<template>
    <div>
        <p style="font-size: 20px;margin-left: 20px;margin-right: 20px;font-weight: bold;">{{source.title}}: {{source.subtitle}}</p>
        <div class="audio" @click="load">
            <span v-if="!start"  @click="audio.play()">
                <van-icon name="play-circle" color="#e2ba7f" size="39" />
            </span>
            <span v-else  @click="audio.pause()">
                <van-icon name="pause-circle" color="#e2ba7f" size="39" />
            </span>
            <span>{{current}}</span>
            <div>
                <van-slider
                        v-model="second"
                        active-color="#e2ba7f"
                        class="audio_slider"
                        :min="0"
                        :max="duration"
                        @drag-start="dragging = true"
                        @drag-end="dragging = false"
                        @change="onChange">
                    <div slot="button" class="van-slider__button" style="background-color: #e2ba7f;height: 12px;width: 12px;"></div>
                </van-slider>
            </div>
            <span>{{total}}</span>
        </div>
        <div class="class_detail">
            <div v-for="item in contents">
                <p v-if="item.type === 1" v-html="item.content"></p>
                <p v-if="item.type === 2">
                    <van-image :src="item.content">
                        <template v-slot:loading>
                            <van-loading type="spinner" size="20" />
                        </template>
                    </van-image>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import {Toast,Dialog} from 'vant';
    import {mapState} from 'vuex';
    import {getQueryVariable} from "../../utils/public";
    export default {
        name: "LessonDetail",
        data(){
            return{
                lesson_id: 0, // 课程id
                audio:null, // 音频实例
                second: 0, // 当前播放的秒数
                start: false, // 播放状态
                duration: 0, // 总时间
                dragging: false,
                source:{},
                contents:[],
                loading:true,
            }
        },
        computed:{
            ...mapState(['course']),
            // 计算当前播放时间（分:秒）
            current(){
                let minute = parseInt(this.second/60).toString();
                let seconds = (this.second%60).toString();
                minute = minute.length === 1? '0' + minute: minute;
                seconds = seconds.length === 1? '0' + seconds: seconds;
                return minute + ':' + seconds ;
            },
            // 计算音频总时间（分:秒）
            total(){
                let minute = parseInt(this.duration/60).toString();
                let seconds = (this.duration%60).toString();
                minute = minute.length === 1? '0' + minute: minute;
                seconds = seconds.length === 1? '0' + seconds: seconds;
                return minute + ':' + seconds ;
            },
        },
        async mounted() {
            // 接收参数
            if(this.$route.query.course_id){
                this.lesson_id = parseInt(this.$route.query.lesson_id);
            } else {
                this.lesson_id = getQueryVariable('lesson_id');
            }
            if(!this.lesson_id){
                Dialog({message:'未找到课程'}).then(()=>{ this.$router.replace('/')});
                return;
            }

            // 返回页首
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            // 初始化音频对象
            this.audio = new Audio();
            // 初始化页面
            await this.loadData();
            // 设置音频资源
            this.audio.src = this.source.url;
            // 给audio添加监听事件
            this.audioEventListeners();
        },
        beforeDestroy(){
            this.audio.pause();
        },
        methods:{
            loadData(){
                let that = this;
                return new Promise((resolve, reject) => {
                    that.post('/api/client/course/detail/lesson',{lesson_id:that.lesson_id}).then(result=> {
                        if(result.code === 0){
                            that.source = result.data.source;
                            that.contents = result.data.contents;
                            if(result.data.source.duration){
                                that.duration = result.data.source.duration;
                            }
                            resolve();
                        } else {
                            Dialog({ message: result.msg });
                            that.$router.replace('/');
                            reject();
                        }
                    }).catch(e=>{
                        console.log(e);
                        Dialog({ message: '课程详情加载失败' });
                        that.$router.replace('/');
                        reject();
                    });
                })
            },
            load(){
                if(this.loading){
                    Toast.loading({
                        duration: 0,
                        forbidClick: true
                    });
                }
            },
            // 进度变化且拖拽结束
            onChange(second) {
                this.audio.currentTime = second;
            },
            // audio监听事件
            audioEventListeners(){
                let that = this;
                // 可开始播放
                this.audio.addEventListener('canplay', function(){
                    that.duration = Math.floor(that.audio.duration);
                    // console.log(that.audio.readyState);//就绪状态
                });
                this.audio.addEventListener('canplaythrough', function(){
                    // 播放就绪
                    that.loading = false;
                    Toast.clear();
                });
                // 当目前的播放位置已更改
                this.audio.addEventListener('timeupdate', function() {
                    if(that.dragging) return; // 如果正在拖拽，不刷新时间
                    that.second = Math.floor(that.audio.currentTime);
                });
                // 播放开始
                this.audio.addEventListener('play',function(){
                    that.start = true;
                });
                // 播放暂停
                this.audio.addEventListener('pause',function(){
                    that.start = false;
                });
                // 音频由于需要缓冲下一帧而停止
                this.audio.addEventListener('waiting', function(){
                    that.start = false;
                });
                // 因缓冲而暂停或停止后已就绪
                this.audio.addEventListener('playing',function(){
                    that.audio.play();
                    that.start = true;
                });
                // 用户已移动/跳跃到音频/视频中的新位置
                this.audio.addEventListener('seeked',function(){
                    that.audio.play();
                    that.start = true;
                });
                // 播放结束
                this.audio.addEventListener( 'ended', function() {
                    that.start = false;
                    console.log('播放结束');
                });
                // 播放发生错误
                this.audio.addEventListener('error', function() {
                    that.start = false;
                    Toast('音频播放失败');
                });
            },
        }
    }
</script>

<style scoped>

</style>